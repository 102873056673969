import barba from '@barba/core';
import barbaCss from '@barba/css';

barba.use(barbaCss);


const body = document.querySelector('body');

// this looks for the 'bakCol' variable set on the next page and sets the background color of the body to that color
barba.hooks.afterLeave((data) => {
    const background = data.next.container.dataset.background;
    body.style.setProperty('background-color', background);
})

barba.init({
    transitions: [
        {
            once() {}
        },
        {
            name: 'fade',
           
            to: {
            namespace: ['fade']
            },
            once() {},
            leave() {},
            enter() {},
            beforeEnter() {
                console.log('fade beforeEnter');
            },
            afterEnter() {
                console.log('fade afterEnter');
            }
        }, 
    ]
});

